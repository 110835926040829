<template lang="pug">
component(:is="to ? 'router-link' : 'button'" type="button" :to="to" :disabled="disabled" :class="classView")
  ui-icon.ui-tab__icon(v-if="iconLeft" :size="22" :icon="iconLeft")
  slot
  ui-icon.ui-tab__icon(v-if="iconRight" :size="22" :icon="iconRight")
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import type { PropType } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";

export default defineComponent({
  name: "UiTab",
  components: {
    UiIcon,
  },
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    size: {
      default: "medium",
      type: String as PropType<"small" | "medium" | "large">,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    iconLeft: String,
    iconRight: String,
    to: String,
  },
  setup(props) {
    const classView = computed(() => {
      const result = ["ui-tab"];

      if (props.size !== "large") result.push("_" + props.size);
      if (props.active) result.push("_active");

      return result;
    });

    return {
      classView,
    };
  },
});
</script>

<style scoped lang="scss">
.ui-tab {
  display: flex;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  outline: none;
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 14px;
  line-height: 16px;
  color: var(--main-color-white);
  background-color: var(--main-color-blue);

  height: 36px;
  width: 240px;

  &._medium {
    width: 180px;
  }

  &._small {
    width: 140px;
  }

  &._active {
    height: 42px;
    background-color: var(--main-color-blue3);
  }
}
</style>
